import React from 'react';
import comfortzone_beer from './imyd_beer.svg';
import comfortzone_wine from './imyd_wine.svg';
import comfortzone_liqueur from './imyd_liqueur.svg';
import comfortzone_spirit from './imyd_spirit.svg';
import './App.css';
import ImageViewer from 'react-simple-image-viewer';
// Material UI components from mui.com
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// window size hook from https://www.freecodecamp.org/news/make-react-apps-responsive/
function useWindowSize() {
  /*const isServerSideRendering = typeof window !== "undefined"; // inverted???
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });*/
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
}

// calculate Fred's age :)
function fredsAge() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth()+1;
  const day = today.getDate();
  let age = year - 1991;
  if (month == 1 && day < 14) {
    age -= 1;
  }
  return age;
}

function laterThan(day: number, month: number, year: number, hour: number, minute: number) {
  const now = new Date();
  const yearNow = now.getFullYear();
  const monthNow = now.getMonth()+1;
  const dayNow = now.getDate();
  const hourNow = now.getHours();
  const minuteNow = now.getMinutes();
  if (yearNow > year) {
    return true;
  } else if (yearNow < year) {
    return false;
  }
  if (monthNow > month) {
    return true;
  } else if (monthNow < month) {
    return false;
  }
  if (dayNow > day) {
    return true;
  } else if (dayNow < day) {
    return false;
  }
  if (hourNow > hour) {
    return true;
  } else if (hourNow < hour) {
    return false;
  }
  return minuteNow >= minute;
}

function showComfortzoneCalculator() {
  return laterThan(14,1,2023,20,0);
}

export default App;

function App() {
  const {width, height} = useWindowSize();
  return (<>
      <Navbar />
      <Content pageWidth={width} />
      <Footer />
    </>);
}

function Navbar() {
  const [navitemsVisible, setNavitemsVisible] = React.useState(false);

  // Used to toggle the menu on small screens when clicking on the menu button
  function navbarToggle() {
    setNavitemsVisible(!navitemsVisible);
  }

  function larger_navbar_item(anchor: string, text: string) {
    return ( <a key={anchor} href={"#"+anchor} className="w3-bar-item w3-button w3-padding-large w3-hide-small" > {text} </a> );
  }
  function small_navbar_item(anchor: string, text: string) {
    /* remove the onclick attribute if you want the navbar to always show on top of the content when clicking on the links */
    return ( <a key={anchor} href={"#"+anchor} className="w3-bar-item w3-button w3-padding-large" onClick={navbarToggle} > {text} </a> );
  }

  const navbar_items = showComfortzoneCalculator() ? [["konzept", "KONZEPT"], ["programme", "PROGRAMM"], ["shop", "SHOP"], ["wohlfuehlpegel", "PEGELRECHNER"]] : [["konzept", "KONZEPT"], ["programme", "PROGRAMM"], ["shop", "SHOP"]];

  return (
  <>
    <div className="w3-top">
      <div className="w3-bar w3-blue w3-card">
        <a
          className="w3-bar-item w3-button w3-padding-large w3-hide-large w3-hide-medium w3-right"
          href="javascript:void()"
          onClick={navbarToggle}
          title="Toggle Navigation Menu"
        >
          {/* menu bar symbol */}
          <i className="fa fa-bars" />
        </a>
        <a href="#" className="w3-bar-item w3-button">
          <img src="imyd_logo.png" alt="HOME" height={40} />
        </a>
        <div className="w3-right w3-padding">
          {navbar_items.map(item => larger_navbar_item(item[0], item[1]))}
        </div>
      </div>
    </div>
    {/* Navbar on small screens  */}
    <div
      id="navDemo"
      className={"w3-bar-block w3-blue w3-hide-large w3-hide-medium w3-top w3-hide" + (navitemsVisible ? " w3-show" : "")}
      style={{ marginTop: 46 }}
    >
      {navbar_items.map(item => small_navbar_item(item[0], item[1]))}
    </div>
  </>
  );
}

function Content(props: {pageWidth: number}) {
  return (

<>
  {/* Page content */}
  <div className="w3-content" style={{ maxWidth: 2000, marginTop: 46 }}>
    {/* Video and Welcome message */}
    <div className="w3-row w3-padding-24">
      {/* video, i.e. Fred picture */}
      <div className="w3-col w3-padding-large w3-margin-top s12 l6">
        <img src="fred.png" width="100%" />
      </div>
      {/* welcome message */}
      <div className="w3-col w3-padding-large s12 l6">
        <h2>IMAKEYOUDRUNK-MOTIVATION©</h2>
        <h3>Mit mehr Motivation zu Deinem Wohlfühlpegel!</h3>
        <p>
          Fehlende Motivation &amp; Disziplin sind die Hauptgründe, warum die
          meisten von uns am Erreichen Ihres Wohlfühlpegels scheitern. Damit ist
          jetzt Schluss! <b>Mit meinem neuen Programm:</b>
        </p>
        <ul>
          <li>Kommst du motiviert an Dein Ziel!</li>
          <li>Lässt Du Deinem inneren Schweinehund keine Chance!</li>
          <li>Gehen wir der Ursache für Deine Contenance auf den Grund!</li>
        </ul>
        <p />
      </div>
    </div>
    {/* Konzept: Wie es funktioniert + Das erhältst du */}
    <div style={{ marginTop: "-100px", marginBottom: 100 }} id="konzept" />
    <div className="w3-row w3-padding-16">
      <div className="w3-col w3-padding-large s12 l6 w3-blue">
        <h2>Wie es funktioniert:</h2>
        <p>
          Unsere Programme bestehen alle aus den <b>3 ELEMENTEN</b>, die für
          einen schnellen und nachhaltigen Trinkerfolg entscheidend
          sind: <b>ERNÄHRUNG, SPORT</b> und <b>MOTIVATION.</b>
          <br />
          Dabei entscheidest Du welchen Schwerpunkt du legen möchtest: Kein
          Sport, ein bisschen Sport oder viel Sport, Du hast die
          Wahl! <b>Fred G.</b> wird Dein Coach, nimmt Dich an die Hand und sorgt
          dafür, dass Du Dein persönliches Ziel erreichen wirst.
        </p>
        <p>
          Wie das funktioniert?
          <br />
          Durch eine Schritt für Schritt Anleitung, viel Motivation und eine
          Community, die Dich unterstützt. So bist Du nie alleine auf Deinem Weg
          zu einem gesünderen und fitteren Körper.
        </p>
      </div>
      <div className="w3-col w3-padding-large s12 l6">
        <h2>Das erhältst Du:</h2>
        <ul>
          <li>
            <b>ERNÄHRUNG:</b> Verschiedene Cocktailideen mit leckeren Rezepten
            zum Nachmischen + deinen Ernährungsplan + inspirierende
            Trichtervideos
          </li>
          <li>
            <b>SPORT:</b> Je nach Intensität: 10-minütige bis 24-stündige,
            effektive Ganzkörper-Workouts, ohne Trainingsgeräte mit Coach Fred
          </li>
          <li>
            <b>MOTIVATION:</b> Motivationsvideos von Fred + Dein
            persönlicher <span style={{ whiteSpace: "nowrap" }}>i-make-you-drunk.com</span> Alkoholtester + Zugang
            zur Teilnehmer-Community.
          </li>
          <li>
            <b>UND NOCH VIEL MEHR:</b> Exakt {fredsAge()} Jahre Erfahrung + 100%
            Erfolgsgarantie + entwickelt und empfohlen von Bier-Olympioniken
            und Ernährungswissenschaftlern.
          </li>
        </ul>
        <p />
        {/* Eating is Cheating, the following is cheating, too:
     With these two empty blocks, we ensure that this colored col (div)
     is at least as high as the other one. */}
        <div className="padding-64">
          <p>&nbsp;</p>
        </div>
        <div className="padding-64">
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    {/* Programme */}
    <div style={{ marginTop: "-80px", marginBottom: 80 }} id="programme" />
    <div className="w3-row w3-padding-16">
      <h2 className="w3-center w3-text-blue">
        Finde heraus, welches Programm zu Dir passt
      </h2>
      <h3 className="w3-center w3-text-dark-grey">
        Wähle aus den drei Schwerpunkten Ernährung, Sport, Motivation
      </h3>
      <div className="w3-col s12 m6 l3 w3-padding-small">
        <div className="w3-amber w3-text-white" style={{ paddingTop: 1 }}>
          <h4 className="w3-padding-small w3-center">Intensivprogramm</h4>
          {/* This div-style-height-overflow img-style-negative-margin-top hack
       is/was required to resize/crop this different image to the right
       size, see https://themetry.com/css-image-cropping/
       Furthermore, this is required to ensure that the leftmost grid
       block has less height as the second one from the left. This is
       necessary so that the third block will be displayed on the left
       side on medium-wide screens. */}
          {/*<div style="height:185px; overflow:hidden;"><img style="margin-top:-40px;" src="p_intensivprogramm.png" width="100%"></div>*/}
          <img id="intensivprogramm" src="p_intensivprogramm.png" width="100%" />
          <p className="w3-padding-small">
            Warst Du heute malochen? Bist Du müde und hast eigentlich keine Lust
            auf all die unsympathischen, hässlichen Leute, die sich Deine
            Freunde nennen? Keine Sorge! Mit unserem Intensivprogramm erfüllst
            Du Deine sozialen Pflichten und bist trotzdem um 22 Uhr im Bett!
            Oder auf dem Stuhl.
          </p>
          <img
            src="q_intensivprogramm.png"
            width="100%"
            className="w3-padding-small w3-opacity"
          />
          <p>&nbsp;</p>
        </div>
      </div>
      <div className="w3-col s12 m6 l3 w3-padding-small">
        <div className="w3-blue" style={{ paddingTop: 1 }}>
          <h4 className="w3-padding-small w3-center">Festivalprogramm</h4>
          <img id="festivalprogramm" src="p_festivalprogramm.png" width="100%" />
          <p className="w3-padding-small">
            Vorbereitung, Planung und Training sind enorm wichtig, um auf
            Großveranstaltungen (z.B. Musikfestivals, Biermessen,
            Feuerwehrfesten, CSU-Parteitagen) zur Höchstform aufzulaufen. Mit
            diesem Programm bestehst Du jeden Trinktriathlon
            (Bier-Korn-Mariacron).
          </p>
          <img
            src="q_festivalprogramm.png"
            width="100%"
            className="w3-padding-small w3-opacity-min"
            style={{ marginBottom: 1 }}
          />
          {/* See above: This eating-is-cheating trick ensures, that the blue block is a little bit higher than the yellow one to the left*/}
          <p>&nbsp;</p>
        </div>
      </div>
      {/* horizontal line that separates 2 upper from 2 lower products */}
      <div className="w3-col m12 w3-padding-large w3-hide-large w3-hide-small">
        <hr />
      </div>
      <div className="w3-col s12 m6 l3 w3-padding-small">
        <div className="w3-dark-grey" style={{ paddingTop: 1 }}>
          <h4 className="w3-padding-small w3-center">Aufbauprogramm</h4>
          <img id="aufbauprogramm" src="p_aufbauprogramm.png" width="100%" />
          <p className="w3-padding-small">
            Das Programm für alle, die eine Zwangspause vom bewussten
            Alkoholgenuss nehmen mussten, z.B. durch eine Schwangerschaft, eine
            akute Lebererkrankung oder eine Entzugskur, und jetzt wieder{" "}
            <b>VOLL EINSTEIGEN</b> wollen. Finde Deinen Weg zurück!
          </p>
          <img
            src="q_aufbauprogramm.png"
            width="100%"
            className="w3-padding-small w3-opacity-min"
          />
          <p>&nbsp;</p>
        </div>
      </div>
      <div className="w3-col s12 m6 l3 w3-padding-small">
        <div className="w3-pink" style={{ paddingTop: 1 }}>
          <h4 className="w3-padding-small w3-center">Kornprogramm</h4>
          <img id="kornprogramm" src="p_kornprogramm.png" width="100%" />
          <p className="w3-padding-small">
            Auch wenn Deutschlands beliebtestes Freizeitgetränk, der Ja! Korn,
            inzwischen an allen Verkaufsstellen vergriffen ist, wollen wir Dir
            nicht vorenthalten, was sich auch mit minderwertigeren
            Ersatzprodukten alles anfangen lässt. Cocktails, Wickel, Atemkuren!
            Kennst Du schon den Bottropolitan?
          </p>
          <img
            src="q_kornprogramm.png"
            width="100%"
            className="w3-padding-small w3-opacity-min"
          />
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    {/*
    Shop
  */}
    <div style={{ marginTop: "-80px", marginBottom: 80 }} id="shop" />
    <div className="w3-row w3-padding-16">
      <h2 className="w3-center w3-text-blue">Shop</h2>
      <h3 className="w3-center w3-text-dark-grey">
        Decke Dich für Deine nächsten Herausforderungen ein
      </h3>
      {/* Biergitte ... leider nicht mehr im Programm
  <p class="w3-medium w3-justify w3-text-dark-grey">
  Der Klassiker unter den Trichtern, von jedem verantwortungsvollen Biergourmet mindestens einmal mit Baumarktutensilien selbstgebaut. Jetzt auf <span style="white-space:nowrap">i-make-you-drunk.com</span> in der praktischen vormontierten Version. Ohne Zeitverlust direkt zum Genuss.
        */}
        <ShopItem title="Bierbarometer" price="9,99€" description="
            Das Bierbarometer — Insbesondere für Einsteiger von Deinem Coach
            empfohlen. Die praktischen, informativen Markierungen helfen Dir,
            Deinen Trainingserfolg zu kontrollieren. Nicht vergessen: Ist es zu
            voll, bist Du (noch) zu leer!"
          images={[
            "s_barometer2.jpg",
            "s_barometer4.jpg",
            "s_barometer5.jpg",
            "s_barometer6.jpg",
            "s_barometer7.jpg"
        ]} />
        <ShopItem title="Bierate" price="79,99€" description="
            Der Profi-Trichter für alle erfahrenen Trichter-Freunde. Der
            Doppelausguss erhöht den Durchsatz und fördert außerdem die soziale
            Komponente des Biergenusses. Gerne auch als Vertrauensspiel auf
            Seminaren oder als Teambuilding-Maßnahme bei führenden
            Wirtschaftsunternehmen eingesetzt."
          images={[
            "s_bierate1.jpg",
            "s_bierate2.jpg",
            "s_bierate3.jpg",
            "s_bierate4.jpg",
            "s_bierate5.jpg",
        ]} />
      {/* horizontal line that separates 2 upper from 2 lower products */}
      <div className="w3-col m12 w3-padding-large w3-hide-large w3-hide-small">
        <hr />
      </div>
      {/* Armin Flaschet und Feinripp Unterhemden */}
        <ShopItem title="Armin Flaschet" price="29,99€" description="
            Armin Flaschet — Das Original — Nur echt mit der patentierten Moritz
            Fiege Doppeldichtung. Eignet sich insbesondere zur spontanen
            Improvisation in Notsituationen, als Reise-Trichter oder als
            Geschenk."
          images={[
            "s_arminflaschet1.jpg",
            "s_arminflaschet2.jpg",
            "s_arminflaschet3.jpg",
            "s_arminflaschet4.jpg",
            "s_arminflaschet5.jpg",
        ]} />
        <ShopItem title="Feinripp Unterhemden" price="25€" description="
            Feinripp Unterhemden, im praktischen Dreierpack: Eins für den
            Alltag, eins für festliche Anlässe und eins für gute Freunde, falls
            jemand sein eigenes Unterhemd vergessen haben sollte."
          images={[
            "s_feinripp1.jpg",
            "s_feinripp2.jpg",
        ]} />
    </div>
    {
      showComfortzoneCalculator() ? 
      <>
        <div style={{ marginTop: "-80px", marginBottom: 80 }} id="wohlfuehlpegel" />
        <div className="w3-row w3-padding-16">
          <h2 className="w3-center w3-text-blue">Wohlfühlpegel-Rechner</h2>
          <h3 className="w3-center w3-text-dark-grey">
            Nutze Freds jahrelange Erfahrung und verwende seine bewährte Erfolgsformel
          </h3>
          <ComfortCalculator pageWidth={props.pageWidth} />
        </div>
      </>
      : <div></div>
    }
    {/* Fat blue footer */}
    <div className="w3-row w3-padding-large w3-padding-32 w3-blue">
      <div className="w3-col l3 m6 s12 w3-center">
        <p>
          <span className="w3-xxxlarge">100%</span>
          <br />
          <span className="w3-large">Erfolgsgarantie</span>
        </p>
      </div>
      <div className="w3-col l3 m6 s12 w3-center">
        <p>
          <span className="w3-xxxlarge">{fredsAge()} Jahre</span>
          <br />
          <span className="w3-large">Erfahrung</span>
        </p>
      </div>
      <div className="w3-col l3 m6 s12 w3-center">
        <p className="w3-padding-8">
          <span className="w3-large">Empfohlen von:</span>
          <br />
          <img src="tag_des_deutschen_bieres.jpg" height={100} />
        </p>
      </div>
      <div className="w3-col l3 m6 s12 w3-center">
        <p>
          <span className="w3-xxxlarge">Note 1,0</span>
          <br />
          <span className="w3-large">besoffene-dekorieren.de</span>
        </p>
      </div>
    </div>
  </div>
</>

  );
}

function ShopItem(props: {title: string, description: string, price: string, images: string[]}) {
  const {title, description, price, images} = props;
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const openImageViewer = React.useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="w3-col s12 m6 l3 w3-padding-large">
      <img
        src={images[0]}
        className="w3-round"
        style={{ width: "100%" }}
        onClick={ () => openImageViewer(0) }
      />
      <div style={{ marginTop: 5 }}>
        {images.map((src, index) => index > 0 && (
          <img
            src={ src }
            onClick={ () => openImageViewer(index) }
            key={ index }
            style={{ marginRight: '2px', width: "20%" }}
            alt=""
          />
        ))}

        <p className="w3-left-align">
          <b>{title}</b>
          <span className="w3-right">
            {price}
            <a href="javascript:alert('Leider derzeit nicht lieferbar.')">
              <img
                src="buy.svg"
                width="35px"
                className="w3-hover-opacity"
                style={{ marginLeft: 10 }}
              />
            </a>
          </span>
        </p>
        <p className="w3-medium w3-justify w3-text-dark-grey">
          {description}
        </p>
        <hr className="w3-hide-large w3-hide-medium" />
        <p />
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
    </div>
  );
}

function ComfortCalculator(props: {pageWidth: number}) {
  const [weight, setWeight] = React.useState<number>(75);
  const [amount, setAmount] = React.useState<number>(0.4);
  const [durationIndex, setDurationIndex] = React.useState<number>(7);
  const [beer, setBeer] = React.useState<boolean>(true);
  const [wine, setWine] = React.useState<boolean>(true);
  const [liqueur, setLiqueur] = React.useState<boolean>(true);
  const [spirit, setSpirit] = React.useState<boolean>(true);

  const durationHours = [
    5/60, 10/60, 15/60, 30/60, 45/60,
    1, 1.5, 2, 3, 4, 5, 6
  ];
  const durationStrings = [
    "5 min", "10 min", "15 min", "30 min", "45 min",
    "1 Std.", "1,5 Std.", "2 Std.", "3 Std.", "4 Std.", "5 Std.", "6 Std."
  ];

  // Taken from https://www.caritas.de/hilfeundberatung/onlineberatung/suchtberatung/haeufiggestelltefragen/wie-berechnet-man-den-promillegehalt-von-alkohol-im-blut-222f4db7-62fd-40a4-a3f8-74e644c9bb12
  //
  // Simplified formulas:
  // 1. We assume the alcohol works instantly (ignore "Etwa 30–60 Minuten nach der Alkoholaufnahme wird die höchste Blutalkoholkonzentration erreicht")
  // 2. Men = Women & Linear decay starts immediately with 0.15 per mille / hour
  // per mille amount pma(t) = alc/g / m/kg / 0.65 - 0.15*t/h
  // -> alc/g = (pma(t) + 0.15*t/h) * m/kg * 0.65
  // -> alc = (amount + 0.15*duration) * weight * 0.65
  const alc = (amount + 0.15*durationHours[durationIndex]) * weight * 0.65;
  // 4. Simplified drinks to cover several different weights of alcohol
  // alc/g = vol/ml * ratio/% * 0.8g/ml
  // Beer = 4.9% 333ml -> ~13g
  // Wine = 10% 200ml -> 16g
  // Liqueur = 30% 20ml -> 4.8g
  // Spirit = 40% 20ml -> 6.0g
  let beerAlc = 13;
  let wineAlc = 16;
  let liqueurAlc = 4.8;
  let spiritAlc = 6;
  //
  // For calculating which drinks we need, we do not try to do something too smart here 
  // (remember https://en.wikipedia.org/wiki/Knapsack_problem).
  // Instead, our algorithm looks like this: Whenever we target more alc than the sum
  // of all (available) drinks, we take (add) one of each. We do this as long as we
  // target more than this aforementioned sum. For the remainder, we try out which
  // combinations of drinks (zero or one for each drink) yields the smallest error.
  function setAlc (beer: boolean, wine: boolean, liqueur: boolean, spirit: boolean) {
    // note: unary +boolean -> int 1 or 0
    return +beer * beerAlc + +wine * wineAlc + +liqueur * liqueurAlc + +spirit * spiritAlc;
  };
  let fullSetAlc = setAlc(beer, wine, liqueur, spirit);
  let numFullSets = Math.floor(alc / fullSetAlc);
  let remainder = alc - numFullSets * fullSetAlc;
  let allCombinations = [];
  for (let i = 0; i < 16; i++) {
    // using numbers 0..15, each bit is a flag for one drink
    let item = {
      alc: 0, // initialization delayed
      hasBeer: !!(i & 0b1000),
      hasWine: !!(i & 0b0100),
      hasLiqueur: !!(i & 0b0010),
      hasSpirit: !!(i & 0b0001),
    };
    item.alc = setAlc(item.hasBeer, item.hasWine, item.hasLiqueur, item.hasSpirit);
    allCombinations.push(item);
  }
  let combinations = allCombinations.filter((item) => {
    let disallow = item.hasBeer && !beer
      || item.hasWine && !wine
      || item.hasLiqueur && !liqueur
      || item.hasSpirit && !spirit;
    return !disallow;
  });
  let errors = combinations.map((item) => Math.abs(remainder - item.alc));
  let index = errors.indexOf(Math.min(...errors));
  let remainderSet = combinations[index];
  let numBeer = +beer * numFullSets + +remainderSet.hasBeer;
  let numWine = +wine * numFullSets + +remainderSet.hasWine;
  let numLiqueur = +liqueur * numFullSets + +remainderSet.hasLiqueur;
  let numSpirit = +spirit * numFullSets + +remainderSet.hasSpirit;

  return (
    <>
      <Container 
        maxWidth="sm">
        <Stack direction="column" spacing={1}>
          <ComfortSlider
                ariaLabel="Gewicht"
                value={weight}
                onChange={(ev: any, val: number) => setWeight(val)}
                min={40} 
                max={150}
                step={5}
                toShow={(value: number) => value+" kg"} />
          <ComfortSlider
                ariaLabel="Wunschpegel"
                value={amount}
                onChange={(ev: any, val: number) => setAmount(val)}
                min={0}
                max={2.0}
                step={0.1}
                toShow={(value: number) => (value.toFixed(1).replace(".", ",")+" ‰")} />
          <ComfortSlider
                ariaLabel="Zeit"
                value={durationIndex}
                onChange={(ev: any, val: number) => setDurationIndex(val)}
                min={0}
                max={durationHours.length-1}
                step={1}
                toShow={(value: number) => "in "+durationStrings[value]} />
          <Box sx={{width: "85%"}}>
            <Stack direction="row" justifyContent="space-evenly">
              <DrinkCheckbox
                label="Bier"
                checked={beer}
                disabled={beer && !(wine || liqueur || spirit)}
                onChange={ (event: any) => setBeer(event.target.checked) } />
              <DrinkCheckbox
                label="Wein"
                checked={wine}
                disabled={wine && !(beer || liqueur || spirit)}
                onChange={ (event: any) => setWine(event.target.checked) } />
              <DrinkCheckbox
                label="Likör"
                checked={liqueur}
                disabled={liqueur && !(beer || wine || spirit)}
                onChange={ (event: any) => setLiqueur(event.target.checked) } />
              <DrinkCheckbox
                label="Schnaps"
                checked={spirit}
                disabled={spirit && !(beer || wine || liqueur)}
                onChange={ (event: any) => setSpirit(event.target.checked) } />
            </Stack>
          </Box>
          <DrinkList pageWidth={props.pageWidth} numBeer={numBeer} numWine={numWine} numLiqueur={numLiqueur} numSpirit={numSpirit} />
        </Stack>
      </Container>
    </>
  );
}

function ComfortSlider(props: {ariaLabel: string, value: number, onChange: any, min: number, max: number, step: number, toShow: any}) {
  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{width: "85%"}}>
        <Slider
          aria-label={props.ariaLabel}
          value={props.value}
          onChange={props.onChange}
          min={props.min}
          max={props.max}
          step={props.step}
          />
      </Box>
      <Box sx={{width: "15%", whiteSpace: "nowrap"}}>
        {props.toShow(props.value)}
      </Box>
    </Stack>
  );
}

function DrinkCheckbox(props: any) {
  return <FormControlLabel
            value={props.label}
            control={<Checkbox checked={props.checked} disabled={props.disabled} onChange={props.onChange} />}
            label={props.label}
            labelPlacement="top" />
}

function DrinkList(props: any) {
  function range(upper: number) {
    return [...Array(upper).keys()];
  }
  // mui uses a scaling factor of 8px, i.e. a spacing of e.g. -5 means -40px
  // additionally, we have at most 600px to fill
  let numItems = props.numBeer + props.numWine + props.numLiqueur + props.numSpirit;
  let spacing = 0;
  let itemWidth = 80;
  let pageWidthAndPadding = props.pageWidth - 16;
  let totalWidth = Math.min(pageWidthAndPadding, 600);
  if (numItems * itemWidth > totalWidth) {
    // numItems * 80 + (numItems-1) * spacing <= totalWidth
    // spacing <= (totalWidth - numItems * 80) / (numItems-1)
    // don't forget the scaling factor
    spacing = (totalWidth - numItems * itemWidth) / (numItems - 1);
    spacing /= 8;
  }
  return (<>
    <Stack direction="row" justifyContent="space-evenly" alignItems="flex-end" spacing={spacing}>
      { range(props.numBeer).map((i) => <img src={comfortzone_beer} key={"beer"+i} width={itemWidth} />) }
      { range(props.numWine).map((i) => <img src={comfortzone_wine} key={"wine"+i} width={itemWidth} />) }
      { range(props.numLiqueur).map((i) => <img src={comfortzone_liqueur} key={"liqueur"+i} width={itemWidth} />) }
      { range(props.numSpirit).map((i) => <img src={comfortzone_spirit} key={"spirit"+i} width={itemWidth} />) }
    </Stack>
  </>);
}

function Footer() {
  return (
    <footer className="w3-container w3-padding-64 w3-center w3-opacity w3-light-grey w3-xlarge">
      <i className="fa fa-facebook-official w3-hover-opacity" />{" "}
      <i className="fa fa-instagram w3-hover-opacity" />{" "}
      <i className="fa fa-snapchat w3-hover-opacity" />{" "}
      <i className="fa fa-pinterest-p w3-hover-opacity" />{" "}
      <i className="fa fa-twitter w3-hover-opacity" />{" "}
      <i className="fa fa-linkedin w3-hover-opacity" />
      <p className="w3-medium">
        Powered by{" "}
        <a href="https://www.w3schools.com/w3css/default.asp" target="_blank">
          w3.css
        </a>
      </p>
      <p className="w3-tiny">
        Alle Rechte vorbehalten. Fred Responsible Drinking, Dortmund Unionsviertel.
      </p>
    </footer>
  );
}
